//
//
//
//
//
//
//

export default {
  data() {
    return {
      date: new Date(process.env.DATE_START),
      daysLeft: 0,
    };
  },

  computed: {
    daysLeftPlur() {
      return this.daysLeft === 1 ? 'day' : 'days';
    },
    label() {
      return `${this.daysLeftPlur} until you move in`;
    },
  },

  beforeMount() {
    this.prefetch();
  },

  methods: {
    prefetch() {
      this.updateDaysLeft();
      return new Promise((resolve) => {
        resolve(this.daysLeft > 0);
      });
    },

    updateDaysLeft() {
      this.daysLeft = Math.floor(
        (this.date.getTime() - Date.now()) / (1000 * 60 * 60 * 24)
      );
    },
  },
};
