//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { MINUTES_IN_HOUR } from '@/constants/date';

export default {
  props: {
    event: {
      type: Date,
      default: null,
    },
  },

  data() {
    return {
      hour: 0,
      minute: 0,
    };
  },

  computed: {
    hourValue() {
      return this.hour * MINUTES_IN_HOUR + this.minute;
    },
    eventValue() {
      const date = this.$day(this.event);
      return (date.hour() % 12) * MINUTES_IN_HOUR + date.minute();
    },
    eventDuration() {
      return process.env.SUNRISE_SUNSET_DURATION_MS / 60 / 1000;
    },
  },

  created() {
    this.updateTime();
    this.createIntervalUpdateTime();
  },

  beforeDestroy() {
    this.removeIntervalUpdateTime();
  },

  methods: {
    updateTime() {
      const date = this.$day();
      this.hour = date.hour() % 12;
      this.minute = date.minute();
    },
    createIntervalUpdateTime() {
      this.timerId = setInterval(
        this.updateTime,
        process.env.TIME_UPDATE_INTERVAL_MS
      );
    },
    removeIntervalUpdateTime() {
      if (this.timerId) {
        clearInterval(this.timerId);
      }
    },
  },
};
