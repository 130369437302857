import axios from 'axios';

const state = {
  lastUpdated: new Date(),
  today: {
    sunrise: null,
    sunset: null,
  },
  tomorrow: {
    sunrise: null,
  },
};

const params = {
  lat: -36.7961955481989,
  lng: 174.7692720740988,
  formatted: 0,
};

const isTimeToUpdate = () =>
  !state.lastUpdated || state.lastUpdated.getDate() < new Date().getDate();

const getEventData = ({ tomorrow = false } = {}) => {
  return axios
    .get(process.env.ENDPOINT_SUNRISE_SUNSET, {
      params: {
        ...params,
        ...(tomorrow && { date: 'tomorrow' }),
      },
    })
    .then((res) => {
      if (res.data.status !== 'OK') {
        throw new Error('[sunrise-sunset]: data retrieval error');
      }
      return res;
    });
};

export async function getUpcomingSunriseSunset() {
  try {
    if (
      isTimeToUpdate() ||
      !state.today.sunrise ||
      !state.today.sunset ||
      !state.tomorrow.sunrise
    ) {
      const { data } = await getEventData();
      state.today.sunrise = data.results.sunrise;
      state.today.sunset = data.results.sunset;

      const { data: tomorrowData } = await getEventData({
        tomorrow: true,
      });
      state.tomorrow.sunrise = tomorrowData.results.sunrise;

      state.lastUpdated = new Date();
    }
  } catch (error) {
    return Promise.reject(error);
  }

  const upcomingEvent = {
    type: 'sunrise',
    date: state.today.sunrise,
    tomorrow: false,
    now: false,
  };
  const parsedSunrise = Date.parse(state.today.sunrise);
  const parsedTomorrowSunrise = Date.parse(state.tomorrow.sunrise);
  const parsedSunset = Date.parse(state.today.sunset);

  const isNow = (ms) =>
    ms <= Date.now() &&
    ms + process.env.SUNRISE_SUNSET_DURATION_MS > Date.now();
  const isNowSunrise = isNow(parsedSunrise);
  const isNowTomorrowSunrise = isNow(parsedTomorrowSunrise);
  const isNowSunset = isNow(parsedSunset);

  const isTodaySunset =
    parsedSunrise + process.env.SUNRISE_SUNSET_DURATION_MS <= Date.now() &&
    parsedSunset + process.env.SUNRISE_SUNSET_DURATION_MS > Date.now();
  const isTomorrowSunrise =
    !isTodaySunset &&
    parsedSunset + process.env.SUNRISE_SUNSET_DURATION_MS <= Date.now();

  upcomingEvent.now = isNowSunrise;

  if (isTodaySunset) {
    upcomingEvent.type = 'sunset';
    upcomingEvent.date = state.today.sunset;
    upcomingEvent.now = isNowSunset;
  }
  if (isTomorrowSunrise) {
    upcomingEvent.type = 'sunrise';
    upcomingEvent.date = state.tomorrow.sunrise;
    upcomingEvent.now = isNowTomorrowSunrise;
    upcomingEvent.tomorrow = true;
  }

  return upcomingEvent;
}
