//
//
//
//
//
//
//
//
//
//
//
//

import { getUpcomingSunriseSunset } from '@/api/sunriseSunset';

export default {
  data() {
    return {
      event: {
        type: null,
        date: null,
        tomorrow: null,
        now: null,
      },
    };
  },

  computed: {
    eventTypeLabel() {
      if (this.event.tomorrow && this.event.type === 'sunrise') {
        return 'Tomorrow sunrise';
      }
      return this.event.type === 'sunset' ? 'Tonight sunset' : 'Today sunrise';
    },
    eventNowTypeLabel() {
      return this.event.type === 'sunset' ? 'Sunset' : 'Sunrise';
    },
    eventDate() {
      return this.$day(this.event.date);
    },
    eventFormattedDate() {
      return this.eventDate.format('hh:mm a');
    },
    eventLabel() {
      if (this.event.now) {
        return `${this.eventNowTypeLabel} is happening now`;
      }
      return `${this.eventTypeLabel} is at ${this.eventFormattedDate}`;
    },
  },

  beforeMount() {
    this.prefetch();
  },

  methods: {
    async prefetch() {
      await this.updateEventData();
      return true;
    },

    updateEventData() {
      return getUpcomingSunriseSunset()
        .then(({ type, date, tomorrow, now }) => {
          this.event.type = type;
          this.event.date = new Date(date);
          this.event.tomorrow = tomorrow;
          this.event.now = now;
        })
        .catch(() => {
          this.event.type = null;
          this.event.date = null;
          this.event.tomorrow = null;
          this.event.now = null;
        });
    },
  },
};
