//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { TYPOGRAPHY_VARIANTS } from '@/constants/design';

export default {
  props: {
    text: {
      type: [String, Number, Array],
      default: null,
    },
    tag: {
      type: String,
      default: 'div',
    },
    variant: {
      type: String,
      default: null,
      validator: (value) => TYPOGRAPHY_VARIANTS.includes(value),
    },
    uppercase: {
      type: Boolean,
      default: false,
    },
    pre: {
      type: Boolean,
      default: false,
    },
  },
};
