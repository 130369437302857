//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDriveTime } from '@/api/drive';

export default {
  data() {
    return {
      time: '12:00',
      period: 'am',
      timerId: null,
      driveTime: null,
    };
  },

  computed: {
    label() {
      return `You’ll be in town in ${this.driveTime}`;
    },
  },

  beforeMount() {
    this.prefetch();
    this.updateTime();
    this.createIntervalUpdateTime();
  },

  beforeDestroy() {
    this.removeIntervalUpdateTime();
  },

  methods: {
    async prefetch() {
      await this.updateDriveTime();
      return true;
    },

    updateDriveTime() {
      return getDriveTime()
        .then(({ durationInTraffic }) => {
          this.driveTime = durationInTraffic;
        })
        .catch(() => {
          this.driveTime = null;
        });
    },
    updateTime() {
      const date = this.$day();
      this.time = date.format('hh:mm');
      this.period = date.format('a');
    },
    createIntervalUpdateTime() {
      this.timerId = setInterval(
        this.updateTime,
        process.env.TIME_UPDATE_INTERVAL_MS
      );
    },
    removeIntervalUpdateTime() {
      if (this.timerId) {
        clearInterval(this.timerId);
      }
    },
  },
};
