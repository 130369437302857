export const TYPOGRAPHY_VARIANTS = [
  'h1',
  'body-1',
  'body-2',
  'body-3',
  'body-4',
  'accent',
  'accent-2',
  'accent-3',
];
