var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"clock"},[(_vm.event)?_c('div',{staticClass:"clock__event",style:({
      '-webkit-transform': ("rotate(" + ((360 / 720) * _vm.eventValue) + "deg)"),
    })},[_c('div',{staticClass:"clock__event-before",style:({
        '-webkit-transform': ("rotate(" + ((360 / 720) * _vm.eventValue * -1) + "deg)"),
      })}),_vm._v(" "),_c('div',{staticClass:"clock__event-after",style:({
        '-webkit-transform': ("rotate(" + ((360 / 720) * _vm.eventDuration) + "deg)"),
      })}),_vm._v(" "),_c('div',{staticClass:"clock__event-cropped"})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"clock__bg"}),_vm._v(" "),_c('div',{staticClass:"clock__arrow is-minute",style:({
      '-webkit-transform': ("rotate(" + ((360 / 60) * _vm.minute) + "deg)"),
    })}),_vm._v(" "),_c('div',{staticClass:"clock__arrow is-hour",style:({
      '-webkit-transform': ("rotate(" + ((360 / 720) * _vm.hourValue) + "deg)"),
    })}),_vm._v(" "),_c('div',{staticClass:"clock__center"})])}
var staticRenderFns = []

export { render, staticRenderFns }