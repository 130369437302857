//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getWeather } from '@/api/weather';

export default {
  data() {
    return {
      description: null,
      temperature: null,
    };
  },

  computed: {
    dateLabel() {
      return this.$day().format('D MMM YYYY');
    },
    temperatureLabel() {
      return `${this.temperature}°`;
    },
  },

  beforeMount() {
    this.prefetch();
  },

  methods: {
    prefetch() {
      return new Promise((resolve) => {
        this.updateWeatherData()
          .then(() => resolve(true))
          .catch(() => resolve(false));
      });
    },

    updateWeatherData() {
      return getWeather().then(({ description, temperature }) => {
        this.description = description;
        this.temperature = temperature;
      });
    },
  },
};
