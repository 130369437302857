import axios from 'axios';

const state = {
  lastUpdated: null,
  data: null,
};

const isTimeToUpdate = () =>
  !state.lastUpdated ||
  state.lastUpdated.getTime() + process.env.DRIVE_UPDATE_INTERVAL_MS <
    Date.now();

export function getDriveTime() {
  if (isTimeToUpdate() || !state.data) {
    return axios.get('/.netlify/functions/drive').then(({ data }) => {
      state.lastUpdated = new Date();
      state.data = data;
      return data;
    });
  }
  return Promise.resolve(state.data);
}
