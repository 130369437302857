//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { slides } from '@/slides';

import { has } from '@/utils/has';

export default {
  data() {
    return {
      activeSlideIndex: 0,
      visibleSlideIndex: 0,
      timerId: null,
    };
  },

  computed: {
    displayedSlides() {
      const slideTypes = process.env.SLIDES.split(' ');
      const activeSlidesByFilter = slideTypes
        .map((t) => slides.filter((s) => s.name === t))
        .flat();
      return activeSlidesByFilter;
    },
    lastSlideIndex() {
      return this.displayedSlides.length - 1;
    },
    currentSlide() {
      return this.displayedSlides[this.activeSlideIndex] || {};
    },
    currentSlideDurationMs() {
      const durationSec =
        this.currentSlide.duration || process.env.SLIDE_DEFAULT_DURATION_SEC;
      return durationSec * 1000;
    },
  },

  watch: {
    activeSlideIndex: 'updateSlide',
  },

  mounted() {
    this.updateSlide();
  },

  methods: {
    async updateSlide() {
      if (!this.$refs.components) {
        setTimeout(this.updateSlide, 1000);
        return;
      }

      const activeComponentRef = this.$refs.components[this.activeSlideIndex];

      if (has.call(activeComponentRef, 'prefetch')) {
        const isPrefetchSuccess = await activeComponentRef.prefetch();
        if (!isPrefetchSuccess) {
          this.next();
          return;
        }
      }

      this.makeActiveSlideVisible();
      this.startSlideTimer();
    },
    makeActiveSlideVisible() {
      this.visibleSlideIndex = this.activeSlideIndex;
    },
    go(index) {
      this.activeSlideIndex = index;
    },
    next() {
      if (this.activeSlideIndex === this.lastSlideIndex) {
        this.go(0);
        return;
      }
      const nextIndex = this.activeSlideIndex + 1;
      this.go(nextIndex);
    },
    startSlideTimer() {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }
      this.timerId = setTimeout(this.next, this.currentSlideDurationMs);
    },
    getComponentAttrs(slide) {
      switch (slide.type) {
        case 'image':
          return {
            is: 'ui-image',
            src: slide.src,
          };
        case 'component':
          return {
            is: slide.component,
          };
        default:
          return {
            is: 'div',
          };
      }
    },
  },
};
