import SlideDayTo from '@/components/SlideDayTo.vue';
import SlideTime from '@/components/SlideTime.vue';
import SlideWeather from '@/components/SlideWeather.vue';
import SlideSun from '@/components/SlideSun.vue';

export const slides = [
  {
    name: 'static',
    type: 'image',
    src: '/images/1.jpg',
  },
  {
    name: 'static',
    type: 'image',
    src: '/images/2.jpg',
  },
  {
    name: 'static',
    type: 'image',
    src: '/images/3.jpg',
  },
  {
    name: 'static',
    type: 'image',
    src: '/images/4.jpeg',
  },
  {
    name: 'static',
    type: 'image',
    src: '/images/5.jpg',
  },
  {
    name: 'dayTo',
    type: 'component',
    component: SlideDayTo,
  },
  {
    name: 'time',
    type: 'component',
    component: SlideTime,
  },
  {
    name: 'weather',
    type: 'component',
    component: SlideWeather,
  },
  {
    name: 'sun',
    type: 'component',
    component: SlideSun,
  },
];
